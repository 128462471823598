/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ModulesToReactComponents } from '@h/modules'
import connect from '@h/connect-redux'
import { smartContainer } from '@h/smart-container'
import { includes } from '@r/contentful-selectors'

// This selector is to remove margin top from the first heading
// so that we can top align it
const outerStyle = { '> *:first-child h1,h2,h3,h4,h5,h6': { mt: 0 } }

const RichText = ({ content: document, includes, extraData, variant, styling }) => {
  const pickVariant = variant =>
    variant === 'base' || variant === undefined ? 'base' : `variants.${variant}`

  return (
    <div
      sx={{
        outerStyle,
        variant: `elements.rich-text-block.${pickVariant(variant)}`,
        ...styling,
      }}
    >
      <For each='group' index='key' of={smartContainer(document.content, includes)}>
        <div sx={{ paddingTop: group.spacingOverride && group.spacingOverride.top }} key={key}>
          {ModulesToReactComponents(group, extraData)}
        </div>
      </For>
    </div>
  )
}

const ConnectedRichText = connect(state => {
  return {
    includes: includes(state),
  }
})(RichText)

export default ConnectedRichText
